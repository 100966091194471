@import url("https://use.typekit.net/uwr1dtp.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: stolzl, sans-serif;
  font-style: normal;
  color: #000D38;
}
.nexd-sea {color: #38F;}
.nexd-navy {color: #455CAA;}
h1 {
  @apply font-bold text-[40px] leading-10 uppercase;
}

.headLine {
  @apply leading-[30px] text-[32px] lg:text-[54px] lg:leading-[50px] font-bold uppercase;
}
.subHeadLine {
  @apply text-[24px] leading-[24px] font-bold uppercase text-[#455CAA];
}
.bodyText {
  @apply text-[19px] leading-[100%] font-light;
  strong {
    @apply font-normal;
  }
}

/**/


.floating {
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
}

.floating.circle {
  animation-name: floating-circle;
}

.floating.square {
  animation-name: floating-square;
}


@keyframes floating-circle {
  from {
    transform: translate(0, 8px) ;
  }
  65% {
    transform: translate(0, 0px);
  }
  to {
    transform: translate(0, 8px);
  }
}

@keyframes floating-square {
  from {
    transform: translate(0, 0px) rotate(-45deg);
  }
  65% {
    transform: translate(0, 8px) rotate(-60deg);
  }
  to {
    transform: translate(0, -0px) rotate(-45deg);
  }
}

ul.arrowlist {
  @apply flex flex-col gap-5;
}
ul.arrowlist li {
  @apply flex gap-5 items-center text-[19px] leading-[100%] font-light;

}
ul.arrowlist li::before {
  content: url("data:image/svg+xml,%3Csvg width='31' height='46' viewBox='0 0 31 46' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_235_14)'%3E%3Cpath d='M4.8109 11.4834L13.8909 20.6602C15.9187 22.7096 15.9187 26.0368 13.8909 28.0862L5.03257 37.039C1.75688 40.3496 4.08024 46 8.70232 46H9.15386C10.5331 46 11.8549 45.4441 12.8236 44.465L29.4648 27.6465C31.4926 25.597 31.4926 22.2698 29.4648 20.2204L13.4558 4.02417C12.446 3.00361 11.0667 2.44769 9.64644 2.48918L8.3493 2.52237C3.79289 2.64683 1.59268 8.22259 4.81911 11.4834H4.8109Z' fill='%23D4F7F5'/%3E%3Cpath d='M2.34798 9.82395L11.428 19.0007C13.4558 21.0501 13.4558 24.3773 11.428 26.4268L2.56964 35.3795C-0.706049 38.6901 1.61731 44.3405 6.2394 44.3405H6.69093C8.07017 44.3405 9.39194 43.7846 10.3607 42.8056L27.0018 25.987C29.0297 23.9376 29.0297 20.6104 27.0018 18.561L10.9928 2.36472C9.98304 1.34416 8.6038 0.78824 7.18352 0.829727L5.88638 0.862916C1.32997 0.987375 -0.870244 6.57143 2.34798 9.82395Z' stroke='%233388FF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_235_14'%3E%3Crect width='31' height='46' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}